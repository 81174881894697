import * as React from "react";

import Footer from "components/uikit/Footer/Footer";
import Amplify from "aws-amplify";
import awsconfig from "aws-exports";
import "assets/scss/material-kit-react.scss";
import ScrollTop from "components/molecures/ScrollTop";
import { Footer as FooterType } from "API";

type Props = {
  children: React.ReactNode;
  footers: FooterType[];
};

export default function Common(props: Props) {
  const { children, footers } = props;
  React.useEffect(() => {
    Amplify.configure({
      ...awsconfig,
      aws_appsync_authenticationType: "API_KEY",
    });
  }, []);

  return (
    <>
      <div style={{ backgroundColor: "#ffffff" }}>{children}</div>
      <ScrollTop showBelow={false} />

      <Footer footers={footers} />
    </>
  );
}
