/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import { List, ListItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { ReactComponent as Logo } from "assets/img/footerlogo.svg";
import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import { Footer as FooterType } from "API";

const useStyles = makeStyles(styles);
type Props = {
  whiteFont: boolean;
  footers:FooterType[]
};
export default function Footer({whiteFont,footers}:Props) {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });

  const validFooters = Array.isArray(footers) ? footers : [];

  validFooters.sort((a:FooterType, b:FooterType) => b.priority - a.priority);
const positionOneFooters = validFooters.filter(item => item.position === 1);
const positionTwoFooters = validFooters.filter(item => item.position === 2);
const positionThreeFooters = validFooters.filter(item => item.position === 3);

  return (
    <footer className={footerClasses}>
      <Box alignItems="center" flexDirection="column" className={classes.root}>
        <Box>
            <List className={classes.list}>
              {positionOneFooters.map((footerItem:FooterType) => (
                <ListItem className={classes.inlineBlock}>
                <a
                    href={footerItem.link}
                    className={classes.block}
                  >
                  <Typography variant="subtitle1">{footerItem.name}</Typography>
                </a>
                  
              </ListItem>)) }
          </List>

          <List className={classes.mobileList}>
              {/* TODO: URL */}
              <ListItem className={classes.inlineBlock}>
                <Typography className={classes.sectionTitle}>関連サイト</Typography>
              </ListItem>        
            
              {positionTwoFooters.map((footerItem:FooterType) => (
                <ListItem className={classes.inlineBlock}>
                <a
       href={footerItem.link}
                    className={classes.block}
                    target="_blank"
                  >
                  <Typography variant="subtitle1">{footerItem.name}</Typography>
                </a>
                  
              </ListItem>)) }
            </List>
        </Box>

        <Box m={2} marginTop={2} justifyContent="center" style={{ borderBottom: '0.5px solid #DDDDDD' }}>
        <a href="/" style={{ textDecoration: 'none' }}>
    <Logo style={{ marginBottom: "-6px" }} />
  </a>
        </Box>

        <Box>
              <List> 
                 {positionThreeFooters.map((footerItem:FooterType) => (
                <ListItem className={classes.socialBlock}>
                  <a className={classes.footerBlock} href={footerItem.link} target="_blank">
                    <i className={classes.socialIcons + " fab fa-facebook"} />
                    <Typography style={{fontSize: "15px", color: "#DDDDDD"}}>
                    {footerItem.name}
                    </Typography>
                  </a>
                </ListItem>   )) }     
           
                
              </List>
            </Box>

        <Box marginTop={4} style={{ opacity: 0.5 }} className={classes.rightBlock}>© {currentYear} KAZI Co., Ltd.</Box>
      </Box>
    </footer>
  );
}
